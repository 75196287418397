<template>
  <div class="styles">
    <small class="over">Treee</small>
    <h1 class="h1">Design</h1>
    <p class="lead">Basic UI Components for Treee app.</p>
    <h2 class="h2">Enviroment</h2>
    <h3 class="h3">Elevation</h3>
    <table>
      <thead>
        <tr><th>z-index</th><th>components</th></tr>
      </thead>
      <tbody>
        <tr>
          <td>0-99</td>
          <td>
            <p>base contents</p>
            <ul>
              <li>Treee editor</li>
              <li>My treee list</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>100-199</td>
          <td>
            <p>covoring components</p>
            <ul>
              <li></li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>200-299</td>
          <td>
            <p>modeless components</p>
            <ul>
              <li>toast</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>300-399</td>
          <td>
            <p>modal components</p>
            <ul>
              <li>dialog veil</li>
              <li>dialog</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>400-</td>
          <td>do not use</td>
        </tr>
      </tbody>
    </table>
    <h2 class="h2">typography</h2>
    <div class="typographyExamples">
      <h1 class="h1-example">h1 element typography is like this</h1>
      <h2 class="h2-example">h2 element typography is like this</h2>
      <h3 class="h3-example">h3 element typography is like this</h3>
      <h4 class="h4-example">h4 element typography is like this</h4>
      <h5 class="h5-example">h5 element typography is like this</h5>
      <h6 class="h6-example">h6 element typography is like this</h6>
      <p class="subtitle1-example">subtitle-1 element typography is like this</p>
      <p class="subtitle2-example">subtitle-2 element typography is like this</p>
      <p class="body1-example">body-2 element typography is like this</p>
      <p class="body2-example">body-2 element typography is like this</p>
      <p class="button-example">button element typography is like this</p>
      <p class="caption-example">caption element typography is like this</p>
      <p class="overline-example">overline element typography is like this</p>
    </div>
    <h2 class="h2">Buttons</h2>
    <div class="types">
      <div class="type">
        <h3 class="h3">Flat</h3>
        <div class="description"><p class="paragraph">hoge hoge hoge hoge hoge hoge hoge hoge hoge hoge hoge hoge hoge hoge.</p></div>
        <h4 class="h4">Enable</h4>
        <button class="button flat">Label Text</button>
        <h4 class="h4">Disabled</h4>
        <button class="button flat" disabled>Label Text</button>
      </div>
      <div class="type">
        <h3 class="h3">Outlined</h3>
        <div class="description"><p class="paragraph">hoge</p></div>
        <h4 class="h4">Enable</h4>
        <button class="button outlined">Label Text</button>
        <h4 class="h4">Disabled</h4>
        <button class="button outlined" disabled>Label Text</button>
      </div>
      <div class="type">
        <h3 class="h3">Raised-Primary</h3>
        <div class="description"><p class="paragraph">hoge</p></div>
        <h4 class="h4">Enable</h4>
        <button class="button primary">Label Text</button>
        <h4 class="h4">Disabled</h4>
        <button class="button primary" disabled>Label Text</button>
      </div>
      <div class="type">
        <h3 class="h3">Raised-Danger</h3>
        <div class="description"><p class="paragraph">hoge</p></div>
        <h4 class="h4">Enable</h4>
        <button class="button danger">Label Text</button>
        <h4 class="h4">Disabled</h4>
        <button class="button danger" disabled>Label Text</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Styles'
}
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.styles {
  margin: 0;
  padding: 20px;
}

.h1 {
  @extend %typo-h1;

  margin: 0 0 20px;
}

.h2 {
  @extend %typo-h2;

  margin: 0 0 20px;
}

.h3 {
  @extend %typo-h3;

  margin: 0 0 20px;
}

.h4 {
  @extend %typo-h4;

  margin: 0 0 20px;
}

.over {
  @extend %typo-overline;
}

.lead {
  @extend %typo-body1;

  margin: 0 0 40px;
}

.paragraph {
  @extend %typo-body2;
}

.types {
  display: flex;
  flex-wrap: wrap;
}

.type {
  flex: 1 0 25%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.description {
  flex: 1 0 auto;
}

.button {
  @extend %typo-button;

  line-height: 1;
  border: none;
  box-sizing: border-box;
  height: 34px;
  min-width: 120px;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    filter: brightness(95%);
  }

  &:active {
    filter: brightness(90%);
  }
}

.button.flat {
  background-color: transparent;
  color: $theme-a;
}

.button.outlined {
  background-color: $white;
  border: solid 1px $gray-2;
  color: $gray-2;
}

.button.primary {
  background-color: $theme-a;
  color: $white;
}

.button.danger {
  background-color: $red-1;
  color: $white;
}

.button.flat[disabled] {
  color: $gray-3;
  cursor: default;

  &:hover {
    filter: none;
  }
}

.button.outlined[disabled] {
  background-color: $white;
  color: $gray-4;
  cursor: default;
  border: solid 1px $gray-4;

  &:hover {
    filter: none;
  }
}

.button.danger[disabled],
.button.primary[disabled] {
  background-color: $gray-4;
  color: $gray-3;
  cursor: default;

  &:hover {
    filter: none;
  }
}

.typographyExamples {
  padding: 20px;
  background-color: $backgroundColor;
}

.h1-example {
  @extend %typo-h1;

  margin: 0 0 10px;
}

.h2-example {
  @extend %typo-h2;

  margin: 0 0 10px;
}

.h3-example {
  @extend %typo-h3;

  margin: 0 0 10px;
}

.h4-example {
  @extend %typo-h4;

  margin: 0 0 10px;
}

.h5-example {
  @extend %typo-h5;

  margin: 0 0 10px;
}

.h6-example {
  @extend %typo-h6;

  margin: 0 0 10px;
}

.subtitle1-example {
  @extend %typo-subtitle1;

  margin: 0 0 10px;
}

.subtitle2-example {
  @extend %typo-subtitle2;

  margin: 0 0 10px;
}

.body1-example {
  @extend %typo-body1;

  margin: 0 0 10px;
}

.body2-example {
  @extend %typo-body2;

  margin: 0 0 10px;
}

.button-example {
  @extend %typo-button;

  margin: 0 0 10px;
}

.caption-example {
  @extend %typo-caption;

  margin: 0 0 10px;
}

.overline-example {
  @extend %typo-overline;

  margin: 0 0 10px;
}
</style>
